import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  NProgress.start()

  document.title = getPageTitle(to.meta.title) // set page title

  if (to.name === null) next({ path: '/', replace: true })
  else next()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/views/home/index'),
    name: 'home'
  },
  {
    path: '/experiences',
    component: () => import('@/views/experiences/index'),
    name: 'experiences'
  },
  {
    path: '/introduction',
    component: () => import('@/views/introduction/index'),
    name: 'introduction'
  },
  {
    path: '/projects',
    component: () => import('@/views/projects/index'),
    name: 'projects'
  },
  {
    path: '/articles',
    component: () => import('@/views/articles/index'),
    name: 'articles'
  }
]

const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

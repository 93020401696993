<template>
  <v-app>
    <v-app-bar absolute app :dense="!isScrollBeTop" :flat="true" color="white">
      <template>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndUp" :class="`${$vuetify.breakpoint.smAndUp ? 'ml-3' : ''}`">
          <v-avatar>
            <v-img :src="imgAvatarSrc" alt="home" />
          </v-avatar>
        </v-app-bar-nav-icon>
        <v-btn v-if="!$vuetify.breakpoint.sm" plain class="mt-1" text @click="onHomeClick"
          ><h3>BLOG OF HAJIKANO</h3></v-btn
        >
      </template>
      <v-spacer></v-spacer>
      <v-row v-if="$vuetify.breakpoint.smAndUp">
        <v-tabs show-arrows hide-slider background-color="rgba(0,0,0,0)" right>
          <v-tab v-for="route in routes" :key="route.name" :to="route.path">
            {{ route.name }}
          </v-tab>
        </v-tabs>
      </v-row>
      <v-app-bar-nav-icon @click="drawer = true" v-else> </v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer height="300" bottom app v-if="$vuetify.breakpoint.xs" v-model="drawer">
      <v-list nav dense>
        <v-tabs grow hide-slider vertical>
          <v-tab v-for="route in routes" :key="route.name" :to="route.path">
            {{ route.name }}
          </v-tab>
        </v-tabs>
      </v-list>
    </v-navigation-drawer>
    <v-main v-scroll="onScroll" class="px-10 mx-auto mainAera">
      <router-view></router-view>
    </v-main>
    <v-footer class="white mt-10 mb-5" padless>
      <v-row justify="center" no-gutters>
        <v-btn color="grey darken-1" text rounded class="my-2" target="_blank" :href="links[0]">
          <strong> 黔ICP备19002039号-1</strong>
        </v-btn>
        <v-btn color="grey darken-1" text rounded class="my-2" target="_blank" :href="links[1]">
          <v-img class="mr-2" :src="imgBeianSrc"></v-img><strong>渝公网安备 50010902000622号</strong>
        </v-btn>
        <v-btn color="grey darken-1" text rounded class="my-2" target="_blank">
          2018 - {{ new Date().getFullYear() }} ©️ kawaiixm.com
        </v-btn>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { imageBaseURL } from '@/settings'
export default {
  name: 'App',

  components: {},

  data: () => ({
    // window's scrollTop,for modify the app-bar's style
    scrollTop: 0,

    // links for footer
    links: ['http://beian.miit.gov.cn', 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010902000622'],

    drawer: false,

    imgBeianSrc: imageBaseURL + '/others/beian.png',
    imgAvatarSrc: imageBaseURL + '/animes/00001.png'
  }),

  mounted() {},

  watch: {},

  computed: {
    // for the style of the app-bar
    isScrollBeTop() {
      return this.scrollTop <= 100
    },

    // for render the tabs of the app-bar
    routes() {
      return this.$router.options.routes
    }
  },

  methods: {
    // when the window is scrolled
    onScroll() {
      this.scrollTop = document.documentElement.scrollTop
    },

    // when click the home button
    onHomeClick() {
      // Pushing the same page is unnecessary
      if (this.$route.path !== '/') this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.mainAera {
  width: 60%;
  min-width: 375px;
}
</style>